import { useParams } from 'react-router-dom'

const NEW = 'new'

const isUUID = (str: string): boolean => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
  return uuidRegex.test(str)
}

export const useGetUUIDFromPath = (): { id?: string; isNew: boolean } => {
  const { id } = useParams<{ id?: string }>()
  if (id && id !== NEW && isUUID(id)) {
    return { id, isNew: false }
  }

  return { isNew: id === NEW }
}

export default useGetUUIDFromPath
